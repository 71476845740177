import React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import bmSignature from "../../static/images/bm-signature-large.png"

const NominationPageTemplate = ({ data }) => (
  <Layout>
    <Helmet>
      <title>
        Nomination for Best Patisserie in Mumbai in 2024 - Deliciae Cakes
      </title>
      <meta
        name="description"
        content="Nomination for Best Patisserie in Mumbai in 2024 - Deliciae Cakes"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Nomination for Best Patisserie in Mumbai in 2024 - Deliciae Cakes"
      />
      <meta
        property="og:description"
        content="Nomination for Best Patisserie in Mumbai in 2024 - Deliciae Cakes"
      />
      <meta
        property="og:image"
        content={
          "https://www.deliciaecakes.com/images/homepage/gifting-square.jpg"
        }
      />
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:title"
        content="Nomination for Best Patisserie in Mumbai in 2024 - Deliciae Cakes"
      />
      <meta
        property="twitter:description"
        content="Custom Cakes: Made-to-order custom cakes that make a special occasion a little more meaningful. Order custom cakes online. Home delivery also available."
      />
      <meta
        property="twitter:image"
        content={
          "https://www.deliciaecakes.com/images/homepage/gifting-square.jpg"
        }
      />
    </Helmet>
    <div className={"light-bg"}>
      <div className="container py-5 pb-0" style={{ minHeight: "80vh" }}>
        <div className="row">
          <div className="col-md-12">
            <h1 className={"text-center mb-5"}>Vote for Deliciae</h1>

            <p>
              After having been awarded as the Best Patisserie in Mumbai for
              four consecutive years, I am elated to share that Deliciae has
              once again been{" "}
              <strong>nominated for the Best Patisserie in Mumbai 2024</strong>{" "}
              by the Times Food Awards
              <span aria-label="star" role="img">
                ⭐
              </span>
            </p>

            <p>
              Please vote for Deliciae by giving a missed call on{" "}
              <a
                style="text-decoration: underline; font-weight: bold;"
                href="tel:07969347329"
              >
                07969347329
              </a>{" "}
              (Click to call directly)
            </p>

            <img
              src={bmSignature}
              alt="Bunty Mahajan"
              style={{ width: "200px" }}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

const NominationPage = (result) => {
  return <NominationPageTemplate data={result.data} />
}

export default NominationPage
